import { tabletMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';
import RichText from '../rich-text/RichText';
import { MidHeading, TextLink } from '../typography';

export const ContentHubSearchContainer = styled.section`
  width: 100%;
`;

export const ContentHubSearchWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 25px;
  flex: 1;
`;

export const SearchResultsContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

export const SearchResult = styled.div`
  background-color: ${({ theme }) => theme.white};
`;

export const LoadingBox = styled.div`
  align-self: center;
  padding: 100px 0;
`;

export const SearchNoResults = styled.div`
  align-self: center;
  margin: 50px auto;
  text-align: center;

  svg {
    color: ${({ theme }) => theme.primary};
  }
`;

export const SearchLink = styled(TextLink)`
  b {
    background-color: #feede6;
  }
`;

export const SearchContent = styled(RichText)`
  padding-bottom: 20px;
  margin-bottom: 25px;
  border-bottom: 1px solid ${({ theme }) => theme.backgroundLighter};

  b {
    background-color: #feede6;
  }
`;

export const TotalResults = styled(MidHeading)`
  @media (${tabletMax}) {
    font-size: 22px;
  }
`;
