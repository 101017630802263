import defaultAvatar from '@propertypal/shared/src/resources/icons/user/default-profile-pic.png';
import React from 'react';
import { Avatar, AvatarLoading, AvatarProps } from './ProfileAvatar.style';

interface ProfileAvatarProps {
  src?: string | null;
  testID?: string;
  alt?: string;
  isLoading?: boolean;
  fullWidth?: boolean;
  borderWidth?: number;
  mr?: AvatarProps['$mr'];
  mt?: AvatarProps['$mt'];
  width?: AvatarProps['$width'];
  boxShadow?: AvatarProps['$boxShadow'];
  va?: AvatarProps['$va'];
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({
  testID,
  alt,
  src,
  isLoading,
  mr,
  mt,
  width,
  boxShadow,
  va,
  fullWidth,
  borderWidth,
}) => {
  if (isLoading) {
    return (
      <AvatarLoading
        data-testid={testID || 'profile-avatar-loading'}
        className="loading"
        $mr={mr}
        $mt={mt}
        $width={width}
        $va={va}
        $fullWidth={fullWidth}
        $borderWidth={borderWidth}
      />
    );
  }

  return (
    <Avatar
      src={src || defaultAvatar.src}
      $mr={mr}
      $mt={mt}
      $width={width}
      $boxShadow={boxShadow}
      $fullWidth={fullWidth}
      $borderWidth={borderWidth}
      alt={alt || 'Profile image'}
      data-testid={testID || 'profile-avatar'}
    />
  );
};

export default ProfileAvatar;
