import { faUserCircle } from '@fortawesome/pro-regular-svg-icons/faUserCircle';
import logout from '@propertypal/shared/src/utils/logout';
import { ACCOUNT_LINKS, NavLink, SIGN_IN_LINKS } from '@propertypal/web-app/src/constants/navigation';
import useProfileImage from '@propertypal/web-app/src/hooks/useProfileImage';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import ProfileAvatar from '../profile/ProfileAvatar';
import { Text } from '../typography';
import { HeaderButton, HeaderIcon, LogOutLinkButton, UserLinkBox, UserSubMenuBox } from './ProfileDropdown.style';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  loggedIn?: boolean;
  textColor: string;
  bgColor: string;
}

const ProfileDropdown: FunctionComponent<Props> = (props) => {
  const profileImage = useProfileImage(props.loggedIn);
  // props.loggedIn not available on static pages
  const loggedIn = props.loggedIn || !!profileImage;
  const items = loggedIn ? ACCOUNT_LINKS : SIGN_IN_LINKS;
  const router = useRouter();
  const theme = useTheme();
  const { open, setOpen } = props;

  const toggleUserPanel = () => {
    setOpen(!open);
  };

  return (
    <>
      {!loggedIn && (
        <HeaderButton
          $active={open}
          onClick={toggleUserPanel}
          data-testid="log-in"
          style={{ background: open ? props.bgColor : 'none' }}
        >
          <HeaderIcon>
            <FontAwesomeIcon
              color={open ? theme.primary : props.textColor}
              style={{ fontSize: 18 }}
              icon={faUserCircle}
            />
          </HeaderIcon>

          <Text color={open ? theme.primary : props.textColor} fontSize={12} fontWeight="bold">
            Log in
          </Text>
        </HeaderButton>
      )}

      {loggedIn && (
        <HeaderButton
          onClick={toggleUserPanel}
          data-testid="my-account"
          style={{ background: open ? props.bgColor : 'none' }}
        >
          <HeaderIcon>
            <ProfileAvatar src={profileImage} width={18} borderWidth={1} />
          </HeaderIcon>

          <Text color={open ? theme.primary : props.textColor} fontSize={12} fontWeight="bold">
            My Account
          </Text>
        </HeaderButton>
      )}

      {open && (
        <UserSubMenuBox data-testid="user-sub-box" role="menu" $loggedIn={loggedIn}>
          {items.map((link: NavLink) => (
            <li key={link.text} role="none" data-sub>
              {!!link.url && (
                <UserLinkBox
                  href={link.url}
                  prefetch={false}
                  role="menuitem"
                  data-testid={link.testID}
                  $color={router?.asPath === link.url ? theme.primary : theme.white}
                >
                  {link.text}
                </UserLinkBox>
              )}

              {!link.url && (
                <LogOutLinkButton role="menuitem" onClick={() => logout()} data-testid={link.testID}>
                  {link.text}
                </LogOutLinkButton>
              )}
            </li>
          ))}
        </UserSubMenuBox>
      )}
    </>
  );
};

export default ProfileDropdown;
