import styled, { css } from 'styled-components';
import Loader from '../animation/Loader.style';

const AVA_SIZE = 35;

export interface AvatarProps {
  $mr?: number;
  $mt?: number;
  $width?: number;
  $boxShadow?: boolean;
  $va?: string;
  $fullWidth?: boolean;
  $borderWidth?: number;
}

const getWidth = ({ $width, $fullWidth }: AvatarProps) => {
  if ($width) return `${$width}px`;
  if ($fullWidth) return '100%';
  return `${AVA_SIZE}px`;
};

export const AvatarStyle = css<AvatarProps>`
  width: ${getWidth};
  flex-basis: ${getWidth};
  align-self: center;
  border: solid ${({ $borderWidth }) => $borderWidth || 2}px ${({ theme }) => theme.white};
  border-radius: calc(${getWidth} / 2);

  ${({ $mr }) => ($mr ? `margin-right: ${$mr}px;` : '')}
  ${({ $mt }) => ($mt ? `margin-top: ${$mt}px;` : '')}
  ${({ $va }) => ($va ? `vertical-align: ${$va};` : '')}
`;

export const Avatar = styled.img<AvatarProps>`
  ${AvatarStyle};
  ${({ $boxShadow }) => ($boxShadow ? `box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);` : ``)}
`;

export const AvatarLoading = styled(Loader)<AvatarProps>`
  ${AvatarStyle};
  height: ${getWidth};
  display: flex;
`;
