import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

export const SEARCH_QUERY_PARAM = 'q';

/* istanbul ignore next */
const DEBOUNCE_TIME = process?.env?.JEST_WORKER_ID ? 0 : 500;

const useSearchQuery = () => {
  const router = useRouter();
  const pathname = usePathname();

  // due to Automatic Static Optimisation, pages are hydrated, so router query etc. are null/empty object on mount
  // & we rely on that router query to set initial input text
  // & to decide whether to update the query or not in the useEffect below
  // but we can use the `asPath` value on the router to determine the search params
  // instead, of using the useSearchParams hook or router.query on useRouter in next/router
  const queryParams = useMemo(() => {
    let pathStr: string | undefined;

    const delimiterIdx = router.asPath.indexOf('?');
    if (delimiterIdx >= 0) {
      pathStr = router.asPath.substring(delimiterIdx);
    }

    return new URLSearchParams(pathStr);
  }, [router.asPath]);

  const [searchText, setSearchText] = useState<string>(queryParams.get(SEARCH_QUERY_PARAM) || '');

  // debounce input value and set state value to prevent calling api too many times
  useEffect(() => {
    const timer = setTimeout(() => {
      // only update current route if current path do not equal updated path, e.g. on mount
      const path = `${pathname}${searchText ? `?${SEARCH_QUERY_PARAM}=${searchText}` : ''}`;
      if (path !== router.asPath) router.push(path, undefined, { scroll: false, shallow: true });
    }, DEBOUNCE_TIME);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText, DEBOUNCE_TIME]);

  return { searchText, setSearchText };
};

export default useSearchQuery;
