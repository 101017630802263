import Link from 'next/link';
import styled, { css } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';

type Breadcrumb = { $link?: boolean };

export const BreadcrumbsContainer = styled.ol<{ searchItem?: boolean; mb?: number }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 14px;
  margin: 0 0 ${({ searchItem, mb }) => (searchItem ? 20 : mb != null ? mb : 55)}px 0;
  padding: 0;
`;

export const BreadcrumbItemContainer = styled.li`
  display: flex;
  align-items: center;
  gap: 7.5px;
`;

const breadcrumbItem = css<Breadcrumb>`
  color: ${({ theme, $link }) => ($link ? theme.backgroundMid : theme.textDark)};
  text-decoration: none;
  transition:
    color linear 300ms,
    text-decoration linear 300ms;

  b {
    background-color: #feede6;
  }

  ${({ $link }) =>
    $link
      ? css`
          &:hover {
            text-decoration: underline;
            color: ${({ theme }) => theme.primary};
          }
        `
      : ''}
`;

export const BreadcrumbChevron = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.primary};
  font-size: 12px;
`;

export const BreadcrumbItem = styled.span<Breadcrumb>`
  ${breadcrumbItem}
`;

export const BreadcrumbLink = styled(Link)<Breadcrumb>`
  ${breadcrumbItem}
`;
