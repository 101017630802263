import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import React, { useMemo } from 'react';
import {
  BreadcrumbChevron,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbsContainer,
  BreadcrumbItemContainer,
} from './ContentHubBreadcrumbs.style';
import { ContentHubBreadCrumb } from './ContentHubPageContainer';

interface ContentHubBreadcrumbsProps {
  breadcrumbs: (ContentHubBreadCrumb | undefined)[];
  searchItem?: boolean;
  mb?: number;
}

const ContentHubBreadcrumbs: React.FC<ContentHubBreadcrumbsProps> = ({ breadcrumbs, searchItem, mb }) => {
  const bcrumbs: ContentHubBreadCrumb[] = useMemo(
    () => breadcrumbs.filter((bc) => !!bc) as ContentHubBreadCrumb[],
    [breadcrumbs],
  );

  return (
    <BreadcrumbsContainer
      data-testid="content-hub-breadcrumbs"
      mb={mb}
      searchItem={searchItem}
      vocab={!searchItem ? 'https://schema.org/' : undefined}
      typeof={!searchItem ? 'BreadcrumbList' : undefined}
    >
      {bcrumbs.map((bc, i) => (
        <BreadcrumbItemContainer
          key={`${bc.url}-${bc.title}`}
          data-testid="content-hub-breadcrumb"
          property={!searchItem ? 'itemListElement' : undefined}
          typeof={!searchItem ? 'ListItem' : undefined}
        >
          {bc.url ? (
            <BreadcrumbLink
              prefetch={false}
              href={bc.url}
              property={!searchItem ? 'item' : undefined}
              typeof={!searchItem ? 'WebPage' : undefined}
              $link
              data-testid="content-hub-breadcrumb-link"
            >
              {/* eslint-disable-next-line react/no-unknown-property */}
              <span property={!searchItem ? 'name' : undefined} data-testid="content-hub-breadcrumb-title">
                {bc.title}
              </span>
            </BreadcrumbLink>
          ) : (
            <BreadcrumbItem property={!searchItem ? 'name' : undefined} data-testid="content-hub-breadcrumb-title">
              {bc.title}
            </BreadcrumbItem>
          )}
          {!searchItem && (
            <meta property="position" content={String(i + 1)} data-testid="content-hub-breadcrumb-meta" />
          )}
          {i !== bcrumbs.length - 1 && (
            <BreadcrumbChevron testID="content-hub-breadcrumb-divider" icon={faChevronRight} />
          )}
        </BreadcrumbItemContainer>
      ))}
    </BreadcrumbsContainer>
  );
};

export default ContentHubBreadcrumbs;
