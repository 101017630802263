import styled, { css, keyframes } from 'styled-components';

export interface LoaderProps {
  display?: string;
  border?: string;
  borderRadius?: string;
  borderColour?: string;
  m?: string | number;
  h?: string;
  w?: string;
  maxWidth?: string;
  center?: boolean;
  fontSize?: number;
  mb?: number;
  mr?: number;
  lineHeight?: string;
  verticalAlign?: string;
  transparent?: boolean;
  flexBasis?: string;
}

const animateGradient = keyframes`
  0%{
    background-position: 0% 0%;
  }
  50%{
    background-position: 100% 100%;
  }
  100%{
    background-position:0% 0%;
  }
`;

export const LoaderBackground = css`
  background: #d4d4d4; /* Old browsers */
  background: linear-gradient(to bottom, #d4d4d4 0%, ${({ theme }) => theme.white} 100%);
  background-size: 400% 400%;
  color: transparent;
  opacity: 0.5;
  animation: ${animateGradient} 3s linear infinite;
`;

const Loader = styled.div<LoaderProps>`
  display: ${({ display }) => display || 'inline'};
  ${LoaderBackground};

  ${({ border }) => (border ? `border: ${border};` : '')}
  ${({ borderRadius }) => (borderRadius ? `border-radius: ${borderRadius};` : '')}
  ${({ borderColour }) => (borderColour ? `border-color: ${borderColour};` : '')}
  ${({ m }) => (m ? `margin: ${m}${typeof m === 'string' ? '' : 'px'};` : '')}
  ${({ h }) => (h ? `height: ${h};` : '')}
  ${({ w }) => (w ? `width: ${w};` : '')}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
  ${({ fontSize }) => (fontSize != null ? `font-size: ${fontSize}px;` : '')}
  ${({ mb }) => (mb != null ? `margin-bottom: ${mb}px;` : '')}
  ${({ mr }) => (mr != null ? `margin-right: ${mr}px;` : '')}
  ${({ lineHeight }) => (lineHeight != null ? `line-height: ${lineHeight};` : '')}
  ${({ verticalAlign }) => (verticalAlign != null ? `vertical-align: ${verticalAlign};` : '')}
  ${({ flexBasis }) => (flexBasis != null ? `flex-basis: ${flexBasis};` : '')}
  ${({ transparent }) => (transparent ? 'background: transparent;' : '')}

  ${({ center }) =>
    center
      ? `
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  `
      : ''}
`;

export const LoaderText = styled(Loader).attrs({
  as: 'span',
})``;

export default Loader;
