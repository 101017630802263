import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import {
  CH_BUYING_FEATURE,
  CH_HOME_FEATURE,
  CH_LOGIN_FEATURE,
  CH_RENTING_FEATURE,
  CH_SELLING_FEATURE,
  CH_STUDENTS_FEATURE,
  NEWS_FEATURE,
  PARTIAL_GUIDES_FEATURE,
} from '@propertypal/shared/src/constants/content-hub';
import NewsletterIcon from '@propertypal/shared/src/resources/icons/content-hub/newsletter-icon.svg';
import PPLogo from '@propertypal/shared/src/resources/logos/PropertyPalLogo.svg?url';
import { ContentHubArticleCategory, ContentHubSubCategory } from '@propertypal/shared/src/types/strapi/overrides';
import Button from '@propertypal/web-ui/src/buttons/Button';
import ContentHubSearchResults from '@propertypal/web-ui/src/content-hub/ContentHubSearchResults';
import useSearchQuery, { SEARCH_QUERY_PARAM } from '@propertypal/web-ui/src/hooks/useSearchQuery';
import Chevron from '@propertypal/web-ui/src/icons/Chevron';
import FontAwesomeIcon from '@propertypal/web-ui/src/icons/FontAwesomeIcon';
import ProfileDropdown from '@propertypal/web-ui/src/navigation/ProfileDropdown';
import { Text } from '@propertypal/web-ui/src/typography';
import { useFormik } from 'formik';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import * as yup from 'yup';
import togglePageScroll from '../../utils/togglePageScroll';
import SocialLinks from '../footer/social-links/SocialLinks';
import {
  Container,
  CopyrightBox,
  ErrorText,
  Footer,
  FooterContent,
  FooterLinks,
  Header,
  HeaderContent,
  HeaderPanel,
  HomeLink,
  LinksRow,
  Logo,
  MailBox,
  MailBoxInput,
  Menu,
  MenuButton,
  MenuLink,
  NavBox,
  NavSubBox,
  NavSubLink,
  NewsLetterIconBox,
  ProfileContainer,
  SearchInput,
  SubMenu,
  SubMenuLink,
  SuccessText,
} from './ContentHubLayout.style';

interface Props {
  subCategories: ContentHubSubCategory[];
  children?: React.ReactNode;
}

interface Category {
  label: string;
  slug: ContentHubArticleCategory;
  links: Array<{
    label: string;
    slug: string | null;
    url: string;
  }>;
  hidden?: boolean;
}

const validationSchema = yup.object().shape({
  email: yup.string().trim().email('Invalid email format').required('Please enter your email'),
});

const groupSubCategories = (subCategories: ContentHubSubCategory[]) => {
  const categories: Category[] = [
    { label: 'Buying', slug: 'buying', links: [], hidden: !CH_BUYING_FEATURE },
    { label: 'Selling', slug: 'selling', links: [], hidden: !CH_SELLING_FEATURE },
    { label: 'Renting', slug: 'renting', links: [], hidden: !CH_RENTING_FEATURE },
    { label: 'Students', slug: 'students', links: [], hidden: !CH_STUDENTS_FEATURE },
  ];

  subCategories.forEach((subCategory) => {
    const category = categories.find((c) => c.slug === subCategory.attributes.articleCategory);

    if (!category?.hidden) {
      if (category && subCategory.attributes.articleCategory && subCategory.attributes.subCategory) {
        category.links.push({
          label: subCategory.attributes.subCategory,
          slug: subCategory.attributes.slug,
          url: `/${category.slug}/${subCategory.attributes.slug}`,
        });
      }
    }
  });

  return categories.filter((category) => !category.hidden);
};

const ContentHubLayout: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const router = useRouter();
  const links = groupSubCategories(props.subCategories);
  const searchParams = useSearchParams();
  const { searchText, setSearchText } = useSearchQuery();
  // check whether there is a faq search query
  const searchQuery = searchParams.get(SEARCH_QUERY_PARAM);

  const [activeLink, setActiveLink] = useState('');
  const [profileOpen, setProfileOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const [result, setResult] = useState('');
  const headerRef = useRef<HTMLDivElement | null>(null);

  const toggleMoreMenu = () => {
    setMenuOpen(!menuOpen);
    togglePageScroll('#contentHubMenu', menuOpen);
  };

  const updateProfileOpen = (open: boolean) => {
    setActiveLink('');
    setProfileOpen(open);
  };

  const updateActiveUpdate = (link: string) => {
    setProfileOpen(false);
    setActiveLink(link);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setSending(true);

      const response = await fetch('/api/mailchimp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: values.email,
        }),
      });

      setSending(false);
      setResult(response.ok ? 'success' : 'error');
    },
  });

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      // @ts-ignore
      if (headerRef.current && !headerRef.current.contains(e.target)) {
        setActiveLink('');
        setProfileOpen(false);
      }
    };

    const handleRouteChange = () => {
      // close menu on route.
      setMenuOpen(false);
      togglePageScroll('#contentHubMenu', true);
    };

    const handleRouteChangeComplate = (url: string) => {
      if (typeof window !== 'undefined') {
        const { searchParams: params } = new URL(url, window.location.origin);
        if (!params.get(SEARCH_QUERY_PARAM)) setSearchText('');
      }
    };

    document.addEventListener('click', handleClick);
    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplate);

    return () => {
      document.removeEventListener('click', handleClick);
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChangeComplate);
    };
  }, []);

  return (
    <>
      <Container>
        <Header data-testid="content-hub-layout">
          <HeaderContent ref={headerRef} $menuOpen={menuOpen}>
            {!menuOpen && (
              <HeaderPanel>
                <Link href="/" prefetch={false}>
                  <Text fontSize={10}>
                    <Chevron direction="left" size={8} /> Back to
                  </Text>
                  <Logo src={PPLogo.src} />
                </Link>

                <LinksRow>
                  {CH_HOME_FEATURE && (
                    <NavBox href="/content-hub" prefetch={false} $current={router?.pathname === '/content-hub'}>
                      Home
                    </NavBox>
                  )}

                  {PARTIAL_GUIDES_FEATURE &&
                    links.map((link) => (
                      <NavBox
                        as="button"
                        $active={activeLink === link.label}
                        $current={router.query.category === link.slug}
                        key={link.label}
                        onClick={() => updateActiveUpdate(activeLink === link.label ? '' : link.label)}
                      >
                        {link.label}

                        <NavSubBox style={{ display: activeLink === link.label ? 'block' : 'none' }}>
                          {link.links.map((subLink) => (
                            <NavSubLink
                              href={subLink.url}
                              key={subLink.label}
                              $current={router.query.subCategory === subLink.slug}
                              prefetch={false}
                              data-testid={`content-hub-nav-sub-link${activeLink !== link.label ? '-hidden' : ''}`}
                            >
                              {subLink.label}
                            </NavSubLink>
                          ))}
                        </NavSubBox>
                      </NavBox>
                    ))}

                  {NEWS_FEATURE && (
                    <NavBox
                      href="/news-and-analysis"
                      prefetch={false}
                      $current={router.query.category === 'news-and-analysis'}
                    >
                      News &amp; Analysis
                    </NavBox>
                  )}
                </LinksRow>
              </HeaderPanel>
            )}

            <HeaderPanel>
              <SearchInput>
                <FontAwesomeIcon icon={faSearch} color={theme.primary} />

                <input
                  type="search"
                  value={searchText}
                  onChange={(event) => setSearchText(event.target.value)}
                  aria-label="Content Hub Search"
                  placeholder="Search..."
                  data-testid="content-hub-search-input"
                />
              </SearchInput>

              {CH_LOGIN_FEATURE && (
                <ProfileContainer data-testid="profile-container">
                  <ProfileDropdown
                    open={profileOpen}
                    setOpen={updateProfileOpen}
                    textColor={theme.textDark}
                    bgColor="#222"
                  />
                </ProfileContainer>
              )}

              <MenuButton onClick={toggleMoreMenu}>
                {menuOpen ? (
                  <>
                    <FontAwesomeIcon icon={faTimes} color={theme.textDark} size={20} />
                    <span>Close</span>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faBars} color={theme.textDark} size={20} />
                    <span>Menu</span>
                  </>
                )}
              </MenuButton>
            </HeaderPanel>
          </HeaderContent>
        </Header>

        <Menu id="contentHubMenu" data-testid="content-hub-menu" style={{ display: menuOpen ? 'block' : 'none' }}>
          {CH_HOME_FEATURE && (
            <MenuLink href="/content-hub" $current={router?.pathname === '/content-hub'}>
              Home
            </MenuLink>
          )}

          {PARTIAL_GUIDES_FEATURE &&
            links.map((link) => (
              <React.Fragment key={link.label}>
                <MenuLink as="div" $current={router?.query?.category === link.slug}>
                  {link.label}
                </MenuLink>
                <SubMenu>
                  {link.links.map((subLink) => (
                    <SubMenuLink
                      href={subLink.url}
                      key={subLink.label}
                      prefetch={false}
                      $current={router?.query?.subCategory === subLink.slug}
                    >
                      <span>{subLink.label}</span>

                      <FontAwesomeIcon icon={faChevronRight} size={20} />
                    </SubMenuLink>
                  ))}
                </SubMenu>
              </React.Fragment>
            ))}

          {NEWS_FEATURE && (
            <MenuLink href="/news-and-analysis" $current={router?.query?.category === 'news-and-analysis'}>
              News &amp; Analysis
            </MenuLink>
          )}

          <Link href="/" style={{ padding: 15, display: 'block' }} prefetch={false}>
            <Text fontSize={10} color={theme.white}>
              <Chevron direction="left" size={8} /> Back to
            </Text>

            <Logo src={PPLogo.src} />
          </Link>
        </Menu>

        {/* if search query active, render search markup */}
        {searchQuery ? <ContentHubSearchResults searchQuery={searchQuery} /> : props.children}
      </Container>
      <Footer>
        <FooterContent>
          <MailBox onSubmit={formik.handleSubmit}>
            <NewsLetterIconBox>
              <NewsletterIcon />

              <div>
                <h5>Interested in more?</h5>
                <p>Stay up to date with the latest property news and analysis.</p>
              </div>
            </NewsLetterIconBox>

            {(result === '' || result === 'error') && (
              <>
                <MailBoxInput
                  placeholder="Enter your email address"
                  name="email"
                  value={formik.values.email}
                  onValueChange={formik.handleChange('email')}
                  error={formik.touched.email && formik.errors.email}
                />

                <Button loading={sending} type="submit">
                  Subscribe
                </Button>

                {result === 'error' && (
                  <ErrorText>
                    There was an error trying to sign you up. Please try another email or try again later.
                  </ErrorText>
                )}
              </>
            )}

            {result === 'success' && <SuccessText>Thanks for signing up to our mailing list!</SuccessText>}
          </MailBox>

          <FooterLinks>
            {PARTIAL_GUIDES_FEATURE && (
              <div>
                <h6>GUIDES</h6>

                {CH_BUYING_FEATURE && (
                  <p>
                    <Link href="/buying" prefetch={false}>
                      Buying
                    </Link>
                  </p>
                )}
                {CH_SELLING_FEATURE && (
                  <p>
                    <Link href="/selling" prefetch={false}>
                      Selling
                    </Link>
                  </p>
                )}
                {CH_RENTING_FEATURE && (
                  <p>
                    <Link href="/renting" prefetch={false}>
                      Renting
                    </Link>
                  </p>
                )}
                {CH_STUDENTS_FEATURE && (
                  <p>
                    <Link href="/student" prefetch={false}>
                      Students
                    </Link>
                  </p>
                )}
              </div>
            )}

            <div>
              <h6>QUICK LINKS</h6>

              {NEWS_FEATURE && (
                <p>
                  <Link href="/news-and-analysis" prefetch={false}>
                    News &amp; Analysis
                  </Link>
                </p>
              )}
              <p>
                <Link href="/faq" prefetch={false}>
                  FAQ
                </Link>
              </p>
              <p>
                <Link href="/search" prefetch={false}>
                  Find a Property
                </Link>
              </p>
              <p>
                <Link href="/contact" prefetch={false}>
                  Contact Us
                </Link>
              </p>
            </div>

            <div>
              <h6>FIND US ON SOCIAL</h6>

              <SocialLinks />

              <HomeLink href="/" prefetch={false}>
                <span>
                  <Chevron size={8} direction="left" /> Back to
                </span>

                <img src={PPLogo.src} alt="PropertyPal" />
              </HomeLink>
            </div>
          </FooterLinks>
        </FooterContent>

        <CopyrightBox>
          <p>
            © Copyright PropertyPal.com 2007-2024 |{' '}
            <Link href="/privacy" prefetch={false}>
              Privacy Policy
            </Link>{' '}
            |{' '}
            <Link href="/cookie-policy" prefetch={false}>
              Cookie Policy
            </Link>{' '}
            |{' '}
            <Link href="/terms" prefetch={false}>
              Terms & Conditions
            </Link>
          </p>
        </CopyrightBox>
      </Footer>
    </>
  );
};

export default ContentHubLayout;
