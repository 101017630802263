import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { CH_HOME_FEATURE } from '@propertypal/shared/src/constants/content-hub';
import axios, { STRAPI_URL } from '@propertypal/shared/src/services/axios.strapi';
import { ContentHubFuzzySearch, ContentHubSearchItem } from '@propertypal/shared/src/types/strapi/fuzzy';
import { getArticleCategoryParams } from '@propertypal/shared/src/utils/content-hub';
import highlightTokens from '@propertypal/shared/src/utils/faq/highlightTokens';
import getArticleCategory from '@propertypal/shared/src/utils/getArticleCategory';
import puralise from '@propertypal/shared/src/utils/puralise';
import React, { useEffect, useState } from 'react';
import Loader from 'react-spinners/PulseLoader';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { MidHeading, Text } from '../typography';
import ContentHubBreadcrumbs from './ContentHubBreadcrumbs';
import {
  ContentHubSearchContainer,
  SearchResultsContainer,
  ContentHubSearchWrapper,
  LoadingBox,
  SearchNoResults,
  SearchLink,
  SearchContent,
  TotalResults,
  SearchResult,
} from './ContentHubSearchResults.style';

interface ContentHubSearchResultsProps {
  searchQuery: string;
}

const CONTENT_HUB_URL = '/content-hub';

// Extract the first 200 characters from the HTML string
const truncatedBlurb = (content: string | null, tokens: string[]) => {
  if (content) {
    const truncated = content.replace(`\u00A0`, '').slice(0, 200);
    return `<span>${highlightTokens(truncated, tokens)}${content.length > truncated.length ? '...' : ''}</span>`;
  }
  return '';
};

const ContentHubSearchResults: React.FC<ContentHubSearchResultsProps> = ({ searchQuery }) => {
  const theme = useTheme();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [articles, setArticles] = useState<ContentHubSearchItem[]>([]);
  const [tokens, setTokens] = useState<string[]>([]);
  const [totalResults, setTotalResults] = useState<number>(0);

  const fetchResult = async () => {
    setLoading(true);

    const { searchParams } = getArticleCategoryParams();

    try {
      const result = await axios<ContentHubFuzzySearch>({
        method: 'GET',
        url: `${STRAPI_URL}/fuzzy-search/search`,
        params: {
          query: searchQuery,
          'filters[contentTypes]': 'articles',
          'filters[articles][publishedAt][$notNull]': 'true',
          // filter out any categories hidden by flags
          ...searchParams,
          'populate[articles][content][populate][0]': 'true',
          'populate[articles][subCategories][populate][1]': 'true',
          'pagination[articles][withcount]': 'true',
        },
      });

      setArticles(result.data?.articles?.data);
      setTotalResults(result.data?.articles?.meta.pagination?.total || 0);
      setError(false);
    } catch (err: any) {
      setError(true);
      setArticles([]);
      setTotalResults(0);
    } finally {
      setLoading(false);
    }
  };

  // tokenise search query
  useEffect(() => {
    setTokens(searchQuery.split(/\s+/));
  }, [searchQuery]);

  // fetch articles that match search query
  useEffect(() => {
    fetchResult();
  }, [JSON.stringify(tokens)]);

  return (
    <ContentHubSearchContainer data-testid="article-search-results-container">
      <ContentHubSearchWrapper>
        <SearchResultsContainer>
          {loading && (
            <LoadingBox data-testid="article-search-loading">
              <Loader color={theme.primary} size={20} speedMultiplier={0.6} margin={8} />
            </LoadingBox>
          )}
          {!loading && articles?.length > 0 && (
            <div data-testid="article-search-results">
              <TotalResults fontSize={30} mb={55} data-testid="article-total-results">
                {totalResults} {puralise('result', 'results', totalResults)} for &quot;{searchQuery}&quot;
              </TotalResults>
              {articles?.map((article) => {
                const categoryUrl = `/${article.subCategories?.[0]?.articleCategory}`;
                const subCategoryUrl = `${categoryUrl}/${article.subCategories?.[0]?.slug}`;
                return (
                  <SearchResult key={article.id} data-testid="article-search-result">
                    <SearchLink
                      prefetch={false}
                      // eslint-disable-next-line max-len
                      href={`/${article.subCategories?.[0]?.articleCategory}/${article.subCategories?.[0]?.slug}/${article.slug}`}
                      mb={10}
                      display="inline-block"
                      fontWeight="bold"
                      fontSize={18}
                      dangerouslySetInnerHTML={{ __html: highlightTokens(article.title, tokens) }}
                      data-testid="article-search-result-link"
                    />
                    <ContentHubBreadcrumbs
                      breadcrumbs={[
                        CH_HOME_FEATURE ? { title: 'Home', url: CONTENT_HUB_URL } : undefined,
                        {
                          title: getArticleCategory(article.subCategories?.[0]?.articleCategory),
                          url: categoryUrl,
                        },
                        {
                          title: article.subCategories?.[0]?.subCategory || '',
                          url: subCategoryUrl,
                        },
                        {
                          title: (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: highlightTokens(article.title, tokens),
                              }}
                            />
                          ),
                        },
                      ]}
                      searchItem
                    />
                    <SearchContent html={truncatedBlurb(article.blurb, tokens)} />
                  </SearchResult>
                );
              })}
            </div>
          )}
          {!loading && !articles?.length && !error && (
            <SearchNoResults data-testid="articles-search-no-results">
              <FontAwesomeIcon icon={faSearch} size={40} />
              <MidHeading fontSize={40} mb={10} mt={10}>
                No Results
              </MidHeading>
              <Text fontSize={18}>Try changing your search criteria.</Text>
            </SearchNoResults>
          )}
          {error && (
            <SearchNoResults data-testid="article-search-error">
              <MidHeading fontSize={40} mb={10} mt={10}>
                Error
              </MidHeading>
              <Text fontSize={18}>
                Oops, something has went wrong during your search, contact help@propertypal.com.
              </Text>
            </SearchNoResults>
          )}
        </SearchResultsContainer>
      </ContentHubSearchWrapper>
    </ContentHubSearchContainer>
  );
};

export default ContentHubSearchResults;
