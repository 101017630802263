import axios, { API_URL } from '@propertypal/shared/src/services/axios';
import { getItem, setItem, removeItem } from '@propertypal/shared/src/services/storage';
import { WebsiteUser } from '@propertypal/shared/src/types/websiteUser';
import { PROFILE_IMAGE } from '@propertypal/shared/src/utils/auth';
import { useEffect, useState } from 'react';

const getProfileImage = async () => {
  try {
    const result = await axios<WebsiteUser>(
      {
        method: 'GET',
        url: `${API_URL}/website-user`,
      },
      undefined,
    );

    setItem(PROFILE_IMAGE, result.data.avatarUrl);

    return result.data.avatarUrl;
  } catch {
    // silent error
    return null;
  }
};

const useProfileImage = (loggedIn?: boolean) => {
  const [url, setUrl] = useState<string | null>(null);

  const checkLoginStatus = async () => {
    const cacheImage = await getItem(PROFILE_IMAGE);

    if (cacheImage && loggedIn === false) {
      await removeItem(PROFILE_IMAGE);
    } else if (cacheImage) {
      setUrl(cacheImage);
    } else if (loggedIn) {
      const mProfileImage = await getProfileImage();

      setUrl(mProfileImage);
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, [loggedIn]);

  return url;
};

export default useProfileImage;
