import appConfig from '../../../../app.config.json';
import logging from '../services/logging';
import { urlFromConfig, Config } from './axios';

export const STRAPI_URL = appConfig.strapiUrl;

const strapiAxios = async <T = any>(config: Config): Promise<Response & { data: T }> => {
  const url = urlFromConfig(config);

  try {
    logging.logCrumb({
      message: 'strapi_request',
      metaData: {
        url,
        method: 'GET',
      },
    });

    const result = await fetch(url, {
      method: config.method || 'GET',
      headers: {
        authorization: `Bearer ${appConfig.strapiToken}`,
      },
    });
    const data = await result.json();

    return { ...result, data };
  } catch (error: any) {
    if (error.response) {
      logging.logError(new Error(`Strapi API error ${error.response.status} on ${url}`));
    }

    throw error;
  }
};

export default strapiAxios;
