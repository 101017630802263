export default (text: string | null, tokens?: string[]): string => {
  // Check if tokens array is empty before creating the regex
  // No need to modify the text if no tokens
  if (!tokens?.length) return text || '';

  // Regular expression for case-insensitive matching
  const regex = new RegExp(`\\b(${tokens.join('|')})\\b`, 'gi');

  return (text || '').replace(regex, (match) => `<b>${match}</b>`);
};
